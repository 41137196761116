import React, { useContext } from 'react'
import { DataContext } from '../context/ShoppingCartContext'
import { NavLink } from 'react-router-dom'
import CheckOutItems from '../components/CheckOutItems/CheckOutItems'

const Checkout = () => {

    const styleLink = {
        textDecoration: 'none'
    }

    const [contador, setContador] = useContext(DataContext)
    const totalAmount = contador.reduce((acc, item) => acc + item.price * item.quantity, 0)
    const totalQuantity = contador.reduce((acc, item) => acc + item.quantity, 0)

    const clearCart = () => {
        setContador([])
    }

    return (
        <div className = "mainSubs">
            <h1>Checkout</h1>
            {contador.length > 0 ? (
                <div>
                    <CheckOutItems />
                    <p>Total de productos en el carrito: <b>{totalQuantity}</b></p>
                    <p>Total a Pagar: <b>${totalAmount} MXN</b></p>
                    <NavLink to="/Cart" style={styleLink}>
                        <button className="btn btn-secondary mb-2 me-2"> Regresar </button>
                    </NavLink>
                    <NavLink to="/CreateOrder" style={styleLink}>
                        <button className="btn btn-primary mb-2" onClick={clearCart}> Finalizar Compra</button>
                    </NavLink>
                </div>
            ) : (
                <div>
                    <p>No hay productos en el carrito</p>
                    <NavLink to="/Shop" style={styleLink}>
                        <button className="btn btn-primary mb-2"> Ir a la Tienda</button>
                    </NavLink>
                </div>
            )}
        </div>
    )
    
}

export default Checkout