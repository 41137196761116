import React, { useEffect } from 'react'
import AOS from 'aos'

const HeroFormCard = () => {

    useEffect(() => {
        AOS.init({
            duration: 1000, // Animation duration
            once: true, // Whether animation should happen only once - while scrolling down
        })
    }, [])

    return (
        <div className="heroContactoScreen">
            <section className="row m-auto mainText" data-aos="flip-up">
                <div className="col-sm text-white fadeInDown container">
                    <h2 className="card-title text-center">
                        MI CONTACTO - MIPEKICHA DIGITAL
                    </h2>
                    <p className="card-text fs-3">
                        Estamos felices de que nos contactes, por favor llena el siguiente formulario y nos pondremos en
                        comunicación contigo lo más pronto posible.
                        O si lo prefieres puedes contactarnos por los siguientes medios.
                    </p>
                    <p className="card-text text-center">
                        <i className="fas fa-phone-alt"></i> (61) 83-65-8753 - DGO
                    </p>
                    <p className="card-text text-center mpk_link_color">
                        <i className="fas fa-envelope"></i>
                        <a
                            href="mailto:mipekicha-digital@mipekicha.com?&subject=Quiero%20informacion%20o%20cotizar">mipekicha-digital@mipekicha.com</a>
                    </p>
                    <p className="card-text text-center mpk_link_color">
                        <i className="fas fa-envelope"></i>
                        <a href="mailto:marketing@mipekicha.com">marketing@mipekicha.com</a>
                    </p>
                </div>
            </section>
        </div>
    )
}

export default HeroFormCard