import React, { useEffect } from 'react'
import AOS from 'aos'
import HeroFormCard from '../components/HeroFormCard/HeroFormCard'
import FormCard from '../components/FormCard/FormCard'

const Contacto = () => {

    useEffect(() => {
        AOS.init({
            duration: 1000, // Animation duration
            once: true, // Whether animation should happen only once - while scrolling down
        })
    }, [])

    return (
        <section className = "mainInfo">
            <HeroFormCard />
            <FormCard />
        </section>
    )
}

export default Contacto