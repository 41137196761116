import React, { useEffect } from 'react'
import AOS from 'aos'

const SimpleCard = () => {

    useEffect(() => {
        AOS.init({
          duration: 1000, // Animation duration
          once: true, // Whether animation should happen only once - while scrolling down
        });
      }, [])

    return (
        // Productos Description Section
        <section className="container">
            <div className="row" data-aos="fade-right">
                <div className="card col-sm text-center pt-3 pb-3 border-0">
                    <span className="iconShield">
                        <i className="fa-solid fa-hand-holding-hand fa-3x"></i>
                    </span>
                    <h2 className="card-title">
                        Asesoria
                    </h2>
                    <p className="card-text">
                    Estamos aquí para ayudarte a descubrir lo mejor de la tecnología, sin complicaciones.
                    Nuestra misión es hacer que la tecnología sea accesible para todos, sin importar tu nivel de experiencia.
                    </p>
                </div>
                <div className="card col-sm text-center pt-3 pb-3 border-0">
                    <span className="iconDog">
                        <i className="fa-solid fa-headset fa-3x"></i>
                    </span>
                    <h2 clas="card-title">
                        Personalizacion
                    </h2>
                    <p className="card-text">
                        Todos tenemos necesidades diferentes, por eso nos enfocamos en darte una atención personalizada.
                        Queremos que te sientas cómodo y seguro con nosotros.
                    </p>
                </div>
                <div className="card col-sm text-center pt-3 pb-3 border-0">
                    <span className="iconSecurity">
                        <i className="fa-solid fa-thumbs-up fa-3x"></i>
                    </span>
                    <h2 clas="card-title">
                        Confianza
                    </h2>
                    <p className="card-text">
                        La confianza es la base de cualquier relación, por eso nos esforzamos en ser transparentes y honestos contigo.
                        Queremos que te sientas seguro y tranquilo al comprar con nosotros.
                    </p>
                </div>
            </div>
        </section>
    )
}

export default SimpleCard