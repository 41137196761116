import { useState } from 'react'
import { useContext } from 'react'
import { DataContext } from '../../context/ShoppingCartContext'
import Swal from 'sweetalert2'

const ItemCount = ({ id, name, quantity, desc, price, img, category, available, stock, initial, onAdd }) => {
    const [quantityItemProduct, setQuantityItemProduct] = useState(initial)

    const increment = () => {
        if (quantityItemProduct < stock) {
            setQuantityItemProduct(quantityItemProduct + 1)
        }
    }

    const decrement = () => {
        if (quantityItemProduct > 1) {
            setQuantityItemProduct(quantityItemProduct - 1)
        }
    }

    const [contador, setContador] = useContext(DataContext)
    const addToCart = () => {
        setContador((currentItems) => {
            const isInCart = currentItems.find((item) => item.id === id)
            const currentQuantityByProduct = currentItems.find((item) => item.id === id)?.quantity
      
            if ((currentQuantityByProduct + quantityItemProduct) > stock) {
                Swal.fire({
                    title: "Disculpa, pero este producto está agotado.",
                    text: "¿Quieres que te notifiquemos cuando tengamos más?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Sí, por favor",
                    cancelButtonText: "No, gracias"
                })
                return currentItems
            }

            if (isInCart) {
                Swal.fire({
                    icon: 'success',
                    title: 'Producto agregado!',
                    text: 'Revisa tu carrito para continuar tu compra',
                    timer: 1500,
                    timerProgressBar: true,
                    showCloseButton: true,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                    willClose: () => {
                        // Do something when the alert closes
                    }
                }).then((result) => {
                    if (result.dismiss === Swal.DismissReason.timer) {
                        console.log('Modal Closed');
                    }
                })
                return currentItems.map((item) => item.id === id ? { ...item, quantity: item.quantity + quantityItemProduct } : item)
            } else {
                Swal.fire({
                    icon: 'success',
                    title: 'Producto agregado!',
                    text: 'Revisa tu carrito para continuar tu compra',
                    timer: 1500,
                    timerProgressBar: true,
                    showCloseButton: true,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                    willClose: () => {
                        // Do something when the alert closes
                    }
                }).then((result) => {
                    if (result.dismiss === Swal.DismissReason.timer) {
                        console.log('Modal Closed');
                    }
                })
                return [...currentItems, { id, name, desc, quantity: quantityItemProduct, price, img, category }]
            }
        })
    }

    return (
        <div>
            <div className="itemCount container card border-0" hidden={stock === 0 ? true : false}>
                <div className="row">
                    <button className="col-sm btn btn-primary btn-list" onClick={decrement}><i className="fa-solid fa-minus fa-xs"></i></button>
                    <h4 id={`itemToAdd${id}`} className="col">{quantityItemProduct}</h4>
                    <button className="col-sm btn btn-secondary btn-list" onClick={increment}><i className="fa-solid fa-plus fa-xs"></i></button>
                </div>
            </div>
            <div>
                <button className="btn btn-primary mt-2" onClick={() => addToCart()} disabled={!stock} >
                    Agregar al carrito
                </button>
            </div>
        </div>
    )
}

export default ItemCount
