import ItemCount from '../ItemCount/ItemCount'
import { AVAILABLE, NOT_AVAILABLE } from '../../static/constants/Config'
import { Link } from 'react-router-dom'

const Item = ({ id, name, quantity, desc, price, img, category, available }) => {

    const itemDetails = {
        id,
        name,
        quantity,
        desc,
        price,
        img,
        category,
        available
    }

    const styleDiv = {
        width: '18rem'
    }

    const styleLink = {
        textDecoration: 'none',
        display: 'block'
    }

    const imagePath = process.env.PUBLIC_URL + img

    return (
        <div name={name} className="card rounded cardComponent" style={styleDiv}>
            <img src={imagePath} alt={name} className="card-img-top mt-2 rounded" />
            <div className="card-body">
                <Link id={`btnDetails${id}`} className="btn btn-primary" to={`/Shop/item/${id}`} style={styleLink}> Ver Detalles </Link>
                {/* <button id={`btnAdd${id}`} className="btn mpk_button_primary" disabled={!quantity} onClick={() => addToCart()}>Agregar</button> */}
                <ItemCount {...itemDetails} stock={quantity} initial={quantity === 0 ? 0 : 1} onAdd={(quantity) => console.log('Cantidad Agregada', quantity)} />
                <h5 className="card-title">{name}</h5>
                <p className="card-text"><b>Precio:</b> ${price}</p>
                <p className="card-text"><b>{available === true ? `${AVAILABLE}:` : <span className="not-available">{NOT_AVAILABLE}</span>}</b> {quantity}</p>
                <p className="card-text" name={category}> <b>Categoria:</b> {category}</p>
            </div>
        </div>
    )
}

export default Item
