import React, { useEffect } from 'react'
import AOS from 'aos'

const Thanks = () => {

    useEffect(() => {
        AOS.init({
            duration: 1000, // Animation duration
            once: true, // Whether animation should happen only once - while scrolling down
        })
    }, [])

    return (
        <section class="heroThanksScreen">
            <div class="row m-auto mainText">
                <div class="col-sm text-center">
                    <h1 class="card-title text-white fs-1 fadeInDown">
                        <b class="text-uppercase"> ¡ g r a c i a s !</b>
                    </h1>
                    <p class="fs-3 fadeInDown">
                        Muchas gracias por contactarnos, en breve alguien de nuestro equipo se pondra en contacto
                        contigo.
                        Te invitamos a continuar revisando nuestros productos y servicios.
                    </p>
                </div>
            </div>
        </section>
    )
}

export default Thanks