import React, { useEffect } from 'react'
import AOS from 'aos'
import HeroCard from '../components/HeroCard/HeroCard'
import ThreeCards from '../components/ThreeCards/ThreeCards'

const Nosotros = () => {

    useEffect(() => {
        AOS.init({
            duration: 1000, // Animation duration
            once: true, // Whether animation should happen only once - while scrolling down
        })
    }, [])

    return (
        <section className = "mainInfo">
            <HeroCard title={"NOSOTROS"} description={"Conoce más sobre nosotros"} />
            <ThreeCards />
        </section>
    )
}

export default Nosotros