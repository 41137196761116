import ItemListContainer from "../components/ItemListContainer/ItemListContainer"

const Shop = () => {
    return (
        <main className = "mainSubs">
            <ItemListContainer welcomeText='Bienvenido a mi tienda virtual' />
        </main>
    )
}

export default Shop