import { NavLink } from 'react-router-dom'

const NavItems = ({ textDisplayed, myUrl }) => {

    return (
        <li className="nav-item hover-underline-animation" >
            <NavLink className="nav-link" aria-current="page" to={`/${myUrl}`}>{textDisplayed}
                <div className="line-1"></div>
            </NavLink>
        </li>
    )
}

export default NavItems
