
import React, {useContext} from 'react'
import { DataContext } from '../../context/ShoppingCartContext'
import CheckOutElements from '../CheckOutElements/CheckOutElements'

const CheckOutItems = () => {
    return(
        <div>
            <h2>Panel</h2>
            <CheckOutElements sectionName={'1.- Metodo de Entrega'} />
            <CheckOutElements sectionName={'2.- Direccion de Facturacion'} />
            <CheckOutElements sectionName={'3.- Direccion de Envio'} />
            <CheckOutElements sectionName={'4.- Informacion de Pago'} />
            <CheckOutElements sectionName={'5.- Resumen de Orden'} />
        </div>
    )
}

export default CheckOutItems