import CarouselFade from '../components/CarouselFade/CarouselFade'
import SimpleGrid from '../components/SimpleGrid/SimpleGrid'
import SimpleCard from '../components/SimpleCard/SimpleCard'
import CenterCard from '../components/CenterCard/CenterCard'
import AddressCard from '../components/AddressCard/AddressCard'

const Inicio = () => {

    return (
        <main className="mainInfo">
            <CarouselFade />
            <SimpleGrid />
            <SimpleCard />
            <CenterCard />
            <AddressCard />
        </main>
    )
}

export default Inicio
