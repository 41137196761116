import { useState, useEffect } from 'react'
import { getProductById } from '../../asyncMock'
import ItemDetails from '../ItemDetails/ItemDetails'
import { useParams } from 'react-router-dom'
// import { db } from '../../services/firebaseConfig'
// import { collection, getDocs } from 'firebase/firestore'

const ItemDetailsContainer = () => {
    // This is used in case to doesnt have more queries on firebase
    const [product, setProduct] = useState(null)

    const { itemId } = useParams()

    useEffect(() => {
        const id = parseInt(itemId)
        getProductById(id)
            .then(
                response => {
                    setProduct(response)
                })
            .catch(error => {
                console.log(error)
            })
    }, [itemId])

    /*
   // Start Fire Base
   const { itemId } = useParams()
   const [itemsList, setItemsList] = useState([])
   const itemCollectionRef = collection(db, 'items')

   useEffect(() => {
       const getItems = async () => {
           const data = await getDocs(itemCollectionRef)
           const item = data.docs.find((doc) => doc.id === itemId)
           setItemsList(item.data())
       }
       getItems()
   })
   // Ends Fire Base
*/
    return (
        <div className='ItemDetailsContainer mainSubs'>
            <ItemDetails {...product} />
        </div>
    )
}

export default ItemDetailsContainer
