import React, { useEffect } from 'react'
import AOS from 'aos'

const AddressCard = () => {

    useEffect(() => {
        AOS.init({
            duration: 1000, // Animation duration
            once: true, // Whether animation should happen only once - while scrolling down
        })
    }, [])

    const styleFrame = {
        border: "0",
    }

    return (
        <div className="bg_dark" data-aos="fade-right">
            <section className="container border border-0 bg_dark mpk_link_color">
                <div className="row">
                    <div className="card col-sm text-center pt-3 pb-3 border-0">
                        <iframe id="idGoogleMap" className="frame_responsive pt-3"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3643.6457887853035!2d-104.64186268255615!3d24.043552899999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8662e9c045555555%3A0x52c172cd90427bf2!2sMIPEKICHA!5e0!3m2!1ses!2smx!4v1676419761342!5m2!1ses!2smx"
                            style={styleFrame} allowFullScreen="" loading="lazy"
                            title="map"></iframe>
                    </div>
                    <div className="card col-sm text-center text-white pt-3 pb-3 border-0">
                        <b className="pt-3">DIRECCION</b>
                        <p>
                            Calle Gladiola #106
                            Fracc. Jardines de Durango
                            Durango, Durango 34200
                        </p>
                        <b>CONTACTO</b>
                        <p><i className="fa-solid fa-at email_icon"></i> mipekicha-digital@mipekicha.com</p>
                        <p><i className="fa-solid fa-at email_icon"></i> marketing@mipekicha.com</p>
                        <p>(61) 83-65-8753 - DGO</p>
                        <b>Horario de Atencion</b>
                        <p>Lunes a Viernes de 9:00 am - 18:00 pm</p>
                    </div>
                    <div className="card col-sm text-center text-white pt-3 pb-3 border-0">
                        <b className="pt-3">REDES</b>
                        <span><a href="https://www.facebook.com/mipekichadigital/" rel="noreferrer" target="_blank" className="p-1">Facebook</a><i
                            className="fab fa-facebook-f facebook_icon"></i></span>
                        <span><a href="https://www.instagram.com/mipekichadigital" rel="noreferrer" target="_blank" className="p-1">Instagram</a><i
                            className="fab fa-instagram instagram_icon"></i></span>
                        <span><a href="https://wa.me/message/3QMNTBVEOSI4K1" rel="noreferrer" target="_blank" className="p-1">WhatsApp</a><i
                            className="fa-brands fa-whatsapp whats_app_icon"></i></span>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default AddressCard