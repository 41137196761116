import Item from "../Item/Item"

const ItemList = ({products}) => {
    return (
        <div id="products_container" className="row mt-5">
            {products.map(prod => <Item key={prod.id}{...prod}/>)}
        </div>
    )
}

export default ItemList
