import React, { useEffect } from 'react'
import AOS from 'aos'

const HeroCard = ({title, description}) => {

    useEffect(() => {
        AOS.init({
            duration: 1000, // Animation duration
            once: true, // Whether animation should happen only once - while scrolling down
        })
    }, [])

    const scrollToSection = () => {
        const section = document.getElementById('descriptionSection')
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' })
        }
    }

    const classUsed = title === "SERVICIOS" ? "heroProductsScreen" : "heroNosotrosScreen"

    return (
        <section id="sHeroScreen" className={classUsed}>
            <div className="row m-auto mainText" data-aos="flip-up">
                <div className="col-sm text-center">
                    <h1 className="card-title text-white fs-1 text-uppercase fadeInDown fw-bold">{title}</h1>
                    <p className="card-text text-white fs-3 fadeInDown">
                        {description}
                    </p>
                    <button className="btn mpk_button btn-lg" variant="success" onClick={scrollToSection}>{title}</button>
                </div>
            </div>
        </section>
    )
}

export default HeroCard