import React, { useEffect } from 'react'
import AOS from 'aos'
import HeroCard from '../components/HeroCard/HeroCard'
import CardShow from '../components/CardsShow/CardsShow'

const Servicios = () => {

    useEffect(() => {
        AOS.init({
            duration: 1000, // Animation duration
            once: true, // Whether animation should happen only once - while scrolling down
        })
    }, [])

    return (
        <section className = "mainInfo" >
            <HeroCard title={"SERVICIOS"} description={"Conoce nuestros productos y servicios"} />
            <CardShow />
        </section>
    )
}

export default Servicios
