import React, { useEffect } from 'react'
import AOS from 'aos'

const FormCard = () => {

    useEffect(() => {
        AOS.init({
            duration: 1000, // Animation duration
            once: true, // Whether animation should happen only once - while scrolling down
        })
    }, [])

    return (
        <div className="heroContactoScreen">
            <section className="container">
                <form className="col-sm text-white fadeInDown" id="contactform" action="https://formsubmit.io/send/31909461-65bc-45b4-8a32-b49f206bc643">
                    <input name="_redirect" type="hidden" id="name" value="https://mipekichadigital.com/thanks" />
                    <input name="_formsubmit_id" type="text" className="noDisplayElement" />
                    <div className="group">
                        <div className="row">
                            <div className="col-sm">
                                <label for="inputName" className="form-label">Nombre</label>
                                <input type="text" name="name" className="form-control" id="inputFirstName"
                                    placeholder="Agrega tu Nombre" required />
                            </div>
                            <div className="col-sm">
                                <label for="inputLastName" className="form-label">Apellido</label>
                                <input type="text" name="lastName" className="form-control" id="inputLastName"
                                    placeholder="Agrega tu Apellido" required />
                            </div>
                            <div className="col-md-6">
                                <label for="inputEmail" className="form-label">Correo Electrónico</label>
                                <input type="email" name="email" className="form-control" id="inputEmail"
                                    placeholder="Agrega tu Correo" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <label for="inputPhone" className="form-label">Teléfono</label>
                                <input type="phone" name="phone" className="form-control" id="inputPhone" required />
                            </div>
                            <div className="col-12">
                                <label for="inputMessage" className="form-label">Mensaje</label>
                                <textarea className="form-control" name="message" id="inputMessage" rows="3"
                                    required></textarea>
                            </div>
                            <div className="col-12 pt-3 pb-5">
                                <button type="submit" className="btn mpk_button">Enviar</button>
                            </div>
                        </div>
                    </div>
                </form>
            </section>
        </div>
    )
}

export default FormCard