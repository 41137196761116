import React from 'react'
import PropTypes from 'prop-types'

const ExampleCarouselImage = ({ text }) => {
  // Map the text to the corresponding image file
  const imageMap = {
    "First slide": require('../../static/images/inicio/firstslide.png'),
    "Second slide": require('../../static/images/inicio/secodslide.png'),
    "Third slide": require('../../static/images/inicio/thirdslide.png'),
  };

  return (
    <img
      className="mx-auto img-fluid"
      src={imageMap[text]}
      alt={text}
    />
  )
}

ExampleCarouselImage.propTypes = {
  text: PropTypes.string.isRequired,
}

export default ExampleCarouselImage