import React from 'react'
import { NavLink } from 'react-router-dom'

const CreateOrder = () => {

// Generdate Random ID
const generateRandomId = () => {
    return Math.floor(Math.random() * 1000000)
}

    return (
        <div className="mb-2 mainSubs">
            <h1>Orden Creada</h1>
            <h2>Tu ID de orden es <b>MPK{generateRandomId()}</b></h2>
            <NavLink to="/Shop">
                <button className="btn btn-primary mb-2">Ir a la Tienda</button>
            </NavLink>
        </div>
    )
}

export default CreateOrder