import { useState, useEffect } from 'react'
import { getProducts, getProductsByCategory } from '../../asyncMock'
import { useParams } from 'react-router-dom'
import ItemList from '../ItemList/ItemList.jsx'
import NabSubBar from '../NabSubBar/NabSubBar'
// import { db } from '../../services/firebaseConfig'
// import { collection, getDocs } from 'firebase/firestore'

const ItemListContainer = ({ welcomeText }) => {

        // This is used in case to doesnt have more queries on firebase
        const [products, setProducts] = useState([])

        const { categoryId } = useParams()

        useEffect(() => {
            const asyncFunc = categoryId ? getProductsByCategory : getProducts

            asyncFunc(categoryId)
                .then(
                    response => {
                        setProducts(response)
                    }).catch(error => {
                        console.log(error)
                    })
        }, [categoryId]
        )
    /*
    // Start Fire Base
    const [itemsList, setItemsList] = useState([])
    const { categoryId } = useParams()

    useEffect(() => {
        const getItems = async () => {
            const data = await getDocs(collection(db, 'items'));
            const items = data.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            setItemsList(items);
        }

        const getItemsByCategory = async () => {
            const data = await getDocs(collection(db, 'items'));
            const items = data.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            const filteredItems = items.filter((item) => item.category === categoryId);
            setItemsList(filteredItems);
        }

        if (categoryId) {
            getItemsByCategory();
        } else {
            getItems();
        }
    })
    // Ends Fire Base
    */
/*
    // Start Server Request
    const [products, setProducts] = useState([])
    const { categoryId } = useParams()

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await fetch(`http://localhost:8080/api/products`, {
                    headers: {
                        'x-api-key': '63dbd180099e2177a9632e867cd432edb48750f40ff71826ad7674464fe1c33b'
                    }
                })

                const data = await response.json()

                if (data.status === "success") {
                    const transformedProducts = data.payload.map(product => ({
                        id: product._id,
                        name: product.title,
                        description: product.description,
                        price: product.price,
                        thumbnails: product.thumbnails.split(', '),
                        code: product.code,
                        stock: product.stock,
                        category: product.category
                    }))
                    setProducts(transformedProducts)
                } else {
                    console.error('Error: Failed to fetch products');
                }
            } catch (error) {
                console.error('Error fetching products:', error)
            }
        }

        fetchProducts()
    }, [categoryId])
*/
    return (
        <div>
            <NabSubBar />
            <label className="ps-3">
                <input id="searchText" type="search" className="form-control" placeholder="Busqueda por nombre" min="0"
                    required />
            </label>
            <button id="btnSearch" type="button" className="mpk_button_primary">
                Buscar
            </button>
            <button id="btnClearFilter" type="button" className="mpk_button_secondary">
                Quitar Filtro
            </button>
            <div className="row m-auto">
                <div className="col-sm text-center">
                    <ItemList products={products} />
                </div>
            </div>
        </div>
    )
}

export default ItemListContainer
