import React, { useContext } from 'react'

const CheckOutElements = ({sectionName}) => {
    return (
        <div>
            <h3>{sectionName}</h3>
        </div>
    )
}

export default CheckOutElements
