import Carousel from 'react-bootstrap/Carousel'
import ExampleCarouselImage from '../ExampleCarouselImage/ExampleCarouselImage.jsx'

const CarouselFade = () =>{

  const styleImg1 = {
    height: "500px",
    width: "100%",
    backgroundColor: "#acc5ea",
    objectFit: "cover"
  }

  const displayOff = {
    display: "none"
  }
  return (
    <div id="testimonials-carousel" className="carousel slide" data-bs-ride="false" data-bs-interval="2000">
        <Carousel fade className="carousel-inner">
            <Carousel.Item className="carousel-item active imagePositionFix" style={styleImg1}>
              <ExampleCarouselImage text="First slide" />
              <Carousel.Caption>
                <h3 style={displayOff} >First slide label</h3>
                <p>Ten tu informacion disponible, perfecta para tramites del SAT.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item className="carousel-item imagePositionFix" style={styleImg1}>
              <ExampleCarouselImage text="Second slide" />
              <Carousel.Caption>
                <h3 style={displayOff} >Second slide label</h3>
                <p>No te quedes sin espacio en tu celular.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item className="carousel-item imagePositionFix" style={styleImg1}>
              <ExampleCarouselImage text="Third slide" />
              <Carousel.Caption>
                <h3 style={displayOff}  >Third slide label</h3>
                <p>
                  Ten un buen rendimiento a un buen precio.
                </p>
              </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    </div>
  )
}

export default CarouselFade