import '../../static/fontawesome/all.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { NavLink } from 'react-router-dom'

const NavSubBar = () => {
    return (
        <div className="row m-auto">
            <div className="text-center">
                <div className="row" id="navbarNav">
                    <ul className="navbar-nav">
                        <li className="mb-3" >
                            <NavLink className="col-sm pe-2 nav-item hover-underline-animation" aria-current="page" to="/Shop">Todos Los Productos</NavLink>
                            <NavLink className="col-sm pe-2 nav-item hover-underline-animation" aria-current="page" to="/Shop/category/collares">Collares</NavLink>
                            <NavLink className="col-sm pe-2 nav-item hover-underline-animation" aria-current="page" to="/Shop/category/correas">Correas</NavLink>
                            <NavLink className="col-sm pe-2 nav-item hover-underline-animation" aria-current="page" to="/Shop/category/plaquitas">Plaquitas</NavLink>
                            <NavLink className="col-sm pe-2 nav-item hover-underline-animation" aria-current="page" to="/Shop/category/cinturones">Cinturones</NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default NavSubBar
