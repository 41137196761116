import { AVAILABLE, NOT_AVAILABLE } from "../../static/constants/Config"
import '../../css/style.css'
import { Link } from 'react-router-dom'

const ItemDetails = ({ id, name, quantity, desc, price, img, category, available }) => {
    
    const styleLink = {
        marginTop: '5em'
    }

    const styleDiv = {
        width: '18rem'
    }

    return (
        <div>
            <div name={name} className="card rounded cardComponent text-center">
                <img src={img} alt={name} className="card-img-top mt-2 rounded mx-auto d-block" style={styleDiv}/>
                <div className="card-body">
                    <Link id={`btnDetails${id}`} style={styleLink} className="mpk_button_primary" to={`/Shop`}> Regresar </Link>
                    <h5 className="card-title bodyDescription">{name}</h5>
                    <p className="card-text"><b>Precio:</b> ${price}</p>
                    <p className="card-text">{available === true ? `${AVAILABLE}` : <span className="not-available" >{NOT_AVAILABLE}</span>}: {quantity}</p>
                    <p className="card-text"><b>Descripcion:</b> {desc}</p>
                    <p className="card-text" name={category}><b>Categoria:</b> {category}</p>
                </div>
            </div>
        </div>
    )
}

export default ItemDetails
