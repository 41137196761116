import React, { useEffect } from 'react'
import AOS from 'aos'

const CenterCard = () => {

    useEffect(() => {
        AOS.init({
            duration: 1000, // Animation duration
            once: true, // Whether animation should happen only once - while scrolling down
        })
    }, [])

    const imageMap = {
        "Las mejores marcas": require('../../static/images/inicio/calidad.png'),
    }

    return (
        <section className="container">
            <div className="row" data-aos="fade-right">
                <div className="card col-sm text-center pt-3 pb-3 border-0">
                    <h3 className="card-title m-1">
                        Marcas de calidad
                    </h3>
                    <p>
                        Trabajamos con las mejores marcas para que tengas la mejor experiencia con nuestros productos.
                    </p>
                    <span className="card-img">
                        <img className="rounded m-2 image_responsive" src={imageMap["Las mejores marcas"]}
                            alt="Las mejores marcas"></img>
                    </span>
                </div>
            </div>
        </section>
    )
}

export default CenterCard