import React, { createContext, useState } from 'react'

export const DataContext = createContext(null)

export const DataProvider = ({children}) => {
    const [ contador, setContador ] = useState([])

    return (
        <DataContext.Provider value={[contador, setContador]}>
            {children}
        </DataContext.Provider>
    )
}
